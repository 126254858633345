import React from 'react';
import Layout from '../components/layout/Layout';

const NotFound = () => (
  <Layout>
    <h1>Error de contenido</h1>
    <p>La página que está intentando acceder no existe.</p>
  </Layout>
);

export default NotFound;
